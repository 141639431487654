/* eslint-disable @typescript-eslint/ban-ts-comment */
import type * as monacoApi from 'monaco-editor/esm/vs/editor/editor.api';
import type * as typescript from 'typescript';

type TypeScriptWorker = monacoApi.languages.typescript.TypeScriptWorker;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const worker = (TSWorker: TypeScriptWorker) => {
    //@ts-ignore
    return class CustomTSWorker extends TSWorker {
        getProgram(): typescript.Program {
            //@ts-ignore
            return this._languageService.getProgram();
        }

        /**
         * Get all imports from a source file
         */
        findImports(filename: string): string[] {
            const program = this.getProgram();
            const sourceFile = program.getSourceFile(filename);

            if (!sourceFile) {
                return [];
            }

            // WARNING: This uses internal properties of the TS AST that may change in future releases!
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return (sourceFile as any).imports.map((node: typescript.Node) => (node as any).text);
        }
    };
};

//@ts-ignore
self.customTSWorkerFactory = worker;
